import React from 'react';

const LangaugeSelector = ({changeLanguage}) => {



  return (
    <div className="language-selector">
    <img src="https://upload.wikimedia.org/wikipedia/commons/4/4c/Flag_of_Sweden.svg" alt="Swedish" onClick={() => changeLanguage('sv')} />
    <img src="https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_the_United_Kingdom_%283-5%29.svg" alt="English" onClick={() => changeLanguage('en')} />
    <img src="https://upload.wikimedia.org/wikipedia/en/thumb/b/ba/Flag_of_Germany.svg/1200px-Flag_of_Germany.svg.png" alt="German" onClick={() => changeLanguage('de')} />
    <img src="https://upload.wikimedia.org/wikipedia/en/thumb/c/c3/Flag_of_France.svg/1200px-Flag_of_France.svg.png" alt="French" onClick={() => changeLanguage('fr')} />
    <img src="https://upload.wikimedia.org/wikipedia/en/thumb/9/9a/Flag_of_Spain.svg/1200px-Flag_of_Spain.svg.png" alt="Spanish" onClick={() => changeLanguage('es')} />
  </div>
  );
};

export default LangaugeSelector
