// import React, { useState} from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import Login from './Login';
import Memorial from './Memorial';
// import PrivateRoute from './PrivateRoute'; 
import './App.css';
import './i18n';

function App() {
    // const [loggedIn, setLoggedIn] = useState(false);
    // const [isLoading, setIsLoading] = useState(false);
    // const [email, setEmail] = useState("");
    


    // useEffect(() => {
    //     const user = JSON.parse(localStorage.getItem("user"));
    //     if (user && user.token) {
    //         setLoggedIn(true);
    //         setEmail(user.email || "");
    //         setIsLoading(true);
    //         } else {
    //             setLoggedIn(false);
    //             setEmail("");
    //             setIsLoading(true);
    //     }
    // }, []);

    return (
        <div className="App">
            <BrowserRouter>
                {/* {isLoading ? <>
                <Routes> 
                    <Route path="/login/" element={<Login setLoggedIn={setLoggedIn} email={email} setEmail={setEmail} />} />
                    <Route path="/" element={<PrivateRoute element={<Memorial />} loggedIn={loggedIn}  setLoggedIn={setLoggedIn}/>} />
                </Routes> 
                </> : <>loading</> } */}
                {/* {isLoading ? <> */}
                <Routes> 
                <Route path="/" element={<Memorial />} />
                </Routes> 
                {/* </> : <>loading</> } */}
            </BrowserRouter>
        </div>
    );
}

export default App;


















