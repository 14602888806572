import React from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Button } from '@mui/material';

const MemorialForm = ({
  // loggedIn,
  handleFormSubmit,
  isLoading,
  name,
  setName,
  nameError,
  story,
  setStory,
  storyError,
  author,
  setAuthor,
  authorError,
  date,
  setDate,
  dateError,
  file,
  setFile,
  fileError,
  successMessage,
  errorMessage,
  handleInputChange,
  handleChange,
  handleButtonClick,
  handleDrop,
  handleDragOver,
  fileInputRef
}) => {
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem("user"));

    

  return (
    <div className="memorial-form">
      <form onSubmit={handleFormSubmit}>
      <label htmlFor="name">{t('Name')}</label>
          <input
             type="text"
             id="name"
             name="name"
            // value={user.userName}  
            value= {name}       
            readOnly
            onChange={(e) => setName(e.target.value)}
            required
           />
          <label className="errorLabel" style={{ color: 'red' }}>{nameError}</label>


        <label htmlFor="story">{t('Story')}</label>
        <textarea
          id="story"
          name="story"
          value={story}
          onChange={(e) => setStory(e.target.value)}
        ></textarea>
        <label className="errorLabel" style={{ color: 'red' }}>{storyError}</label>

        <label htmlFor="author">{t('Author')}</label>
        <input
          type="text"
          id="author"
          name="author"
          value={author}
          onChange={(e) => setAuthor(e.target.value)}
        />
        <label className="errorLabel" style={{ color: 'red' }}>{authorError}</label>

        <label htmlFor="date">{t('Date when story happend')}</label>
        <input
          type="date"
          id="date"
          name="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
        />
        <label className="errorLabel" style={{ color: 'red' }}>{dateError}</label>

        <div className="drop-area" onClick={handleButtonClick} onDrop={handleDrop} onDragOver={handleDragOver}>
          <p>{t('dropAreaText')}</p>
          {/* Hidden file input */}
          <input
            ref={fileInputRef}
            type="file"
            id="fileElem"
            className="hidden"
            onChange={handleChange}
            accept="image/*"
          />
        </div>
        <label className="errorLabel" style={{ color: 'red' }}>{fileError}</label>

        <Button variant='contained' color='success' type="submit">{isLoading ? <CircularProgress size={24} style={{ marginLeft: 15 }} /> : t('createButton')}</Button>
        {/* Render loader if isLoading is true */}
        {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      </form>
    </div>
  );
};

export default MemorialForm;
