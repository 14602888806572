import React from 'react';


const MemorialCard = ({ t, onButtonClick }) => (
  <div className="card">
    <div className="invalid-link">
      <h2>{t('notFound')} !</h2>
    </div>
  </div>
);

export default MemorialCard;

