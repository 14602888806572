import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CircularProgress, Container } from "@mui/material";
import MemorialForm from "./MemorialForm";
import LanguageSelector from "./LanguageSelector";
import MemorialCard from "./MemorialCard";

const Memorial = (props) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [story, setStory] = useState("");
  const [photoUrl, setPhotoUrl] = useState("");
  const [author, setAuthor] = useState("");
  const [date, setDate] = useState("");
  // const [mediaId, setMediaId] = useState(null);
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const [nameError, setNameError] = useState("");
  const [storyError, setStoryError] = useState("");
  const [authorError, setAuthorError] = useState("");
  const [dateError, SetDateError] = useState("");
  const [fileError, setFileError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [decodedData, setDecodedData] = useState(null);

  const { i18n } = useTranslation();
  useEffect(() => {
    const getQueryParams = () => {
      const queryParams = new URLSearchParams(window.location.search);
      const paramValue = queryParams.get("parameter");
      if (paramValue) {
        try {
          const decodedParamValue = atob(paramValue);
          const paramArray = JSON.parse(decodedParamValue);
          const refUserId = paramArray?.userId?.trim();
          const userId = paramArray?.userId?.trim();
          const type = paramArray?.type;
          const name = paramArray?.name;

          setDecodedData({ refUserId, type, name });
          setName(name);
          setIsPageLoading(false);
        } catch (error) {
          setIsPageLoading(false);
          console.error("Error decoding base64 parameter:", error);
        }
      }
    };
    getQueryParams();
  }, []);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  const handleInputChange = (e) => {
    setPhotoUrl(e.target.value);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    setNameError("");
    setStoryError("");
    setAuthorError("");
    SetDateError("");
    setFileError("");
    setSuccessMessage(""); // Reset success message on new form submit

    if (story.trim() === "" || author.trim() === "" || date.trim() === "") {
      if (story.trim() === "") {
        setStoryError("Please enter a Story");
      }

      if (author.trim() === "") {
        setAuthorError("Please enter Author name");
      }

      if (date.trim() === "") {
        SetDateError("Please select a Date");
      }
      setIsLoading(false);
      return;
    }

    const formData = {
      name: name,
      story: story,
      authorName: author,
      refUserId: decodedData?.refUserId,
      userId: decodedData?.refUserId,
      date: date,
    };

    if (file) {
      try {
        const media = await handleFileUpload();
        formData.media = media._id;
      } catch (error) {
        console.error("File upload failed:", error);
        // setFileError('Failed to upload file, proceeding without File upload.');
      }
    }

    const username = process.env.REACT_APP_USERNAME;
    const password = process.env.REACT_APP_PASSWORD;
    const encodedCredentials = btoa(`${username}:${password}`);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/memorial/create`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${encodedCredentials}`,
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        setIsLoading(false);
        setName("");
        setStory("");
        setPhotoUrl("");
        setAuthor("");
        setDate("");
        setFile("");
        setSuccessMessage(`${decodedData?.type} submitted successfully!`);
      } else {
        setIsLoading(false);
        setErrorMessage("Failed to submit form");
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMessage("Failed to submit form");
    }
  };
  // const handleLogout = () => {
  //   localStorage.removeItem('user');
  //   navigate(`/login/?type=${type}&userId=${refUserId}`);
  // };

  // const onLogout = () => {
  //   localStorage.removeItem('user');
  //   navigate(`/login/?type=${type}&userId=${refUserId}`);
  // };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files[0].type.startsWith("image/")) {
      setFile(files[0]);
      setFileError("");
    } else {
      setFileError("Please upload an image");
    }
  };

  const handleChange = (e) => {
    const files = e.target.files;
    setFile(files[0]);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleButtonClick = () => {
    fileInputRef.current.click(); // Trigger click on the file input element
  };

  const handleFileUpload = async () => {
    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);

    const username = process.env.REACT_APP_USERNAME;
    const password = process.env.REACT_APP_PASSWORD;
    const encodedCredentials = btoa(`${username}:${password}`);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/media/fileupload`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Basic ${encodedCredentials}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (data._id) {
          // setMediaId(data._id);
          return data;
        }
      } else {
        setFileError("Failed to upload file");
      }
    } catch (error) {
      setFileError("Error uploading file");
    }
  };

  return (
    <>
      <Container>
        {isPageLoading ? (
          <div className="loader-div">
            <CircularProgress size={50} style={{ margin: "auto" }} />
          </div>
        ) : (
          <>
            {decodedData?.refUserId &&
            decodedData?.type &&
            decodedData?.name ? ( // Check if decodedData is available
              <>
                <div className="upper-container-btn">
                  <div>
                    <h1>{t(`title.${decodedData?.type}`)}</h1>
                  </div>
                </div>

                <LanguageSelector changeLanguage={changeLanguage} />

                <div className="description">
                  {t(`description.${decodedData?.type}`)}
                </div>

                <MemorialForm
                  handleFormSubmit={handleFormSubmit}
                  isLoading={isLoading}
                  name={name}
                  setName={setName}
                  nameError={nameError}
                  story={story}
                  setStory={setStory}
                  storyError={storyError}
                  author={author}
                  setAuthor={setAuthor}
                  authorError={authorError}
                  date={date}
                  setDate={setDate}
                  dateError={dateError}
                  file={file}
                  setFile={setFile}
                  fileError={fileError}
                  successMessage={successMessage}
                  errorMessage={errorMessage}
                  handleInputChange={handleInputChange}
                  handleChange={handleChange}
                  handleButtonClick={handleButtonClick}
                  handleDrop={handleDrop}
                  handleDragOver={handleDragOver}
                  fileInputRef={fileInputRef}
                />

                <div className="upload-container">
                  <div id="preview-container">
                    {photoUrl ? (
                      <img src={photoUrl} alt="Preview" />
                    ) : (
                      file && (
                        <img src={URL.createObjectURL(file)} alt="Preview" />
                      )
                    )}
                  </div>
                </div>
              </>
            ) : (
              <MemorialCard t={t} />
            )}
          </>
        )}
      </Container>
    </>
  );
};
// onButtonClick={handleLogout}
export default Memorial;
